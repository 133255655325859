
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import AddSiswa from "@/components/1crm/siswa/add-siswa.vue";

export default defineComponent({
  name: "Add-Siswa",
  components: {
    ErrorMessage,
    Field,
    Form,
    AddSiswa,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SISWA);
      setCurrentPageBreadcrumbs("Tambah Siswa", ["Data"]);
    });

    return {
      submitButton,
    };
  },
});
