
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Multiselect from "@vueform/multiselect";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

interface tambahSiswa {
  identityNumber: string;
  name: string;
  gender: string;
  kelas: string;
  kelasDetail: string;
  placeOfBirth: string;
  dateOfBirth: string;
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
  tags: Array<string>;
  migrationStatus: string;
}

interface onlyId {
  schoolId: string;
}

interface cekSchool {
  cekSchool: string;
}

interface items {
  listSekolah: any;
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;
  prov: string;
  kab: string;
  disableButton: boolean;

  errorschoolId: string;
  erroridentityNumber: string;
  errorname: string;
  errorgender: string;
  errorprovince: string;
  errorcity: string;
  errorregency: string;
  errordetail: string;
  errordateOfBirth: string;
  errorplaceOfBirth: string;
  errorkelas: string;
  errorkelasDetail: string;
}

export default defineComponent({
  name: "Add-Siswa",
  components: {
    ErrorMessage,
    Field,
    Form,
    Multiselect,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      listSekolah: [],
      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
      prov: "",
      kab: "",
      disableButton: false,

      errorschoolId: "",
      erroridentityNumber: "",
      errorname: "",
      errorgender: "",
      errorprovince: "",
      errorcity: "",
      errorregency: "",
      errordetail: "",
      errordateOfBirth: "",
      errorplaceOfBirth: "",
      errorkelas: "",
      errorkelasDetail: "",
    });

    const tambahSiswa = reactive<tambahSiswa>({
      identityNumber: "",
      name: "",
      gender: "",
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
      dateOfBirth: "",
      placeOfBirth: "",
      kelas: "",
      kelasDetail: "",
      tags: [],
      migrationStatus: "ADDED",
    });

    const onlyId = reactive<onlyId>({
      schoolId: "",
    });

    const cekSchool = reactive<cekSchool>({
      cekSchool: "",
    });

    const saveChanges1 = () => {
      if (
        onlyId.schoolId === "" ||
        tambahSiswa.identityNumber === "" ||
        tambahSiswa.name === "" ||
        tambahSiswa.gender === "" ||
        tambahSiswa.address.province === "" ||
        tambahSiswa.address.city === "" ||
        tambahSiswa.address.regency === "" ||
        tambahSiswa.address.detail === "" ||
        tambahSiswa.dateOfBirth === "" ||
        tambahSiswa.placeOfBirth === "" ||
        tambahSiswa.kelas === "" ||
        tambahSiswa.kelasDetail === ""
      ) {
        items.errorschoolId = "Sekolah Wajib Diisi";
        items.erroridentityNumber = "Nomor Identitas Wajib Diisi";
        items.errorname = "Nama Wajib Diisi";
        items.errorgender = "Jenis Kelamin Wajib Diisi";
        items.errorprovince = "Provinsi Wajib Diisi";
        items.errorcity = "Kota/ Kabupaten Wajib Diisi";
        items.errorregency = "Kecamatan Wajib Diisi";
        items.errordetail = "Alamat Wajib Diisi";
        items.errordateOfBirth = "Tanggal Lahir Wajib Diisi";
        items.errorplaceOfBirth = "Tempat Lahir Wajib Diisi";
        items.errorkelas = "Kelas Wajib Diisi";
        items.errorkelasDetail = "Kelas Detail Wajib Diisi";
      } else {
        items.errorschoolId = "";
        items.erroridentityNumber = "";
        items.errorname = "";
        items.errorgender = "";
        items.errorprovince = "";
        items.errorcity = "";
        items.errorregency = "";
        items.errordetail = "";
        items.errordateOfBirth = "";
        items.errorplaceOfBirth = "";
        items.errorkelas = "";
        items.errorkelasDetail = "";

        isLoading.value = true;

        // Activate loading indicator
        items.disableButton = true;
        submitButton.value?.setAttribute("data-kt-indicator", "on");

        // Send login request
        ApiService.postWithData(
          "crmv2/main_student/student/office?schoolId=" + onlyId.schoolId,
          tambahSiswa
        )
          .then((res) => {
            toast.success("Berhasil Tambah Siswa");
            router.push({ path: "/superadmin/siswa" });
            submitButton.value?.removeAttribute("data-kt-indicator");

            isLoading.value = false;
          })
          .catch((e) => {
            if (e.response.status == 401) {
              toast.error(e.response.data.detail);
              router.push("/sign-in");
              isLoading.value = false;
            } else {
              toast.error(e.response.data.detail);
              items.disableButton = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              isLoading.value = false;
            }
          });
      }
    };

    const getListSekolah = () => {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        (response) => {
          items.listSekolah = response.data;
        }
      );
    };

    const pilihSekolah = (event) => {
      if (event) {
        onlyId.schoolId = event;
      } else {
        onlyId.schoolId = "";
      }
    };

    const pilihProv = (event) => {
      if (event) {
        tambahSiswa.address.province = event;
        getKabupaten();
      } else {
        tambahSiswa.address.province = "";
        tambahSiswa.address.city = "";
        tambahSiswa.address.regency = "";
        getKabupaten();
      }
    };

    const pilihKab = (event) => {
      if (event) {
        tambahSiswa.address.city = event;
        getKecamatan();
      } else {
        tambahSiswa.address.city = "";
        tambahSiswa.address.regency = "";
        getKecamatan();
      }
    };

    const pilihKec = (event) => {
      if (event) {
        tambahSiswa.address.regency = event;
        getKecamatan();
      } else {
        tambahSiswa.address.regency = "";
        getKecamatan();
      }
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getKabupaten = () => {
      var indexProv = items.provinsiOptions.findIndex(
        (x) => x.name === tambahSiswa.address.province
      );

      if (indexProv != null) {
        var provId = items.provinsiOptions[indexProv].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getRegencies/" + provId
        ).then((response) => {
          items.prov = "1";
          items.kabupatenOptions = response.data;
        });
      }
    };

    const getKecamatan = () => {
      var indexKab = items.kabupatenOptions.findIndex(
        (x) => x.name === tambahSiswa.address.city
      );
      if (indexKab != null) {
        var kabupatenId = items.kabupatenOptions[indexKab].id;
        ApiService.getWithoutSlug(
          "https://api.katalis.info/wilayah/getDistricts/" + kabupatenId
        ).then((response) => {
          items.kab = "1";
          items.kecamatanOptions = response.data;
        });
      }
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SISWA);

      getProvinsi();
      getListSekolah();
    });

    return {
      submitButton,
      saveChanges1,
      tambahSiswa,
      onlyId,
      items,
      getKabupaten,
      getKecamatan,
      getListSekolah,
      cekSchool,
      pilihSekolah,
      pilihProv,
      pilihKab,
      pilihKec,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
